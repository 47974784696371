import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      let URL = '';
      switch (currentUser.role) {
        case Role.Admin: URL = '/dashboard/analytics'; break;
        case Role.Client: URL = '/dashboard/analytics'; break;
        case Role.User: URL = '/dashboard/analytics'; break;
        case Role.PlutusAdmin: URL = '/administrator/dashboard'; break;
        case Role.Doctor: URL = '/doctor/home'; break;
        case Role.Patient: URL = '/patient/home'; break;
        // role not authorised so redirect to not-authorized page
        default:
          URL = '/pages/miscellaneous/not-authorized'
          break;
      }
      this._router.navigate([URL]);
    } else {
      // not logged in so redirect to login page with the return url
      this._router.navigate(['/pages/authentication/login-v2']);
    }
  }
}
