export class AppointmentsFakeData {
    public static rows = [
      {
        responsive_id: '',
        id: 'DP6723',
        avatar: 'avatar-s-10.jpg',
        full_name: "Dr.Daniel",
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '09/23/2016',
        time: '10.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'KP6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Michael',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        age:25,
        date: '09/23/2021',
        time: '10.15am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'RA6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.John',
        doctorType: 'Veterinary',
        treatment: 'Root Canal',
        age:25,
        date: '10/01/2021',
        time: '10.30am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'RS6823',
        avatar: 'avatar-s-8.jpg',
        full_name: 'Dr.Alex',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        age:25,
        date: '10/02/2021',
        time: '12.10am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'DJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Lexa',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        age:25,
        date: '11/12/2021',
        time: '12.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'KJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/03/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'UJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'Fever',
        age:25,
        date: '11/03/2021',
        time: '11.20am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 1,
      },
      {
        responsive_id: '',
        id: 'DP6723',
        avatar: 'avatar-s-10.jpg',
        full_name: "Dr.Daniel",
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '09/23/2016',
        time: '10.00am',
        status: 1
      },
      {
        responsive_id: '',
        id: 'KP6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Michael',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        age:25,
        date: '09/23/2021',
        time: '10.15am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'RA6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.John',
        doctorType: 'Veterinary',
        treatment: 'Root Canal',
        age:25,
        date: '10/01/2021',
        time: '10.30am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'RS6823',
        avatar: 'avatar-s-8.jpg',
        full_name: 'Dr.Alex',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        age:25,
        date: '10/02/2021',
        time: '12.10am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'DJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Lexa',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        age:25,
        date: '11/12/2021',
        time: '12.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'KJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/03/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'UJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'Fever',
        age:25,
        date: '11/03/2021',
        time: '11.20am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        age:25,
        date: '11/02/2021',
        time: '11.00am',
        status: 2,
      },
    ]
}