export class HistoryFakeData {
    public static rows = [
      {
        responsive_id: '',
        id: 'DP6723',
        avatar: 'avatar-s-10.jpg',
        full_name: "Dr.Daniel",
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '09/23/2016',
        balance: '$50',
        status: 1
      },
      {
        responsive_id: '',
        id: 'KP6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Michael',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        date: '09/23/2021',
        balance: '$30',
        status: 2
      },
      {
        responsive_id: '',
        id: 'RA6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.John',
        doctorType: 'Veterinary',
        treatment: 'Root Canal',
        date: '10/01/2021',
        balance: '$70',
        status: 5
      },
      {
        responsive_id: '',
        id: 'RS6823',
        avatar: 'avatar-s-8.jpg',
        full_name: 'Dr.Alex',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        date: '10/02/2021',
        balance: '$50',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$58',
        status: 2
      },
      {
        responsive_id: '',
        id: 'DJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Lexa',
        doctorType: 'Dentist',
        treatment: 'Cleaning',
        date: '11/12/2021',
        balance: '$40',
        status: 1
      },
      {
        responsive_id: '',
        id: 'KJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/03/2021',
        balance: '$50',
        status: 3
      },
      {
        responsive_id: '',
        id: 'UJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'Fever',
        date: '11/03/2021',
        balance: '$500',
        status: 3
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$150',
        status: 5
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$120',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$70',
        status: 4
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$40',
        status: 4
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$30',
        status: 4
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-2.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$20',
        status: 2
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$150',
        status: 5
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$50',
        status: 5
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-1.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Physician',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$60',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Dentist',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$500',
        status: 1
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-9.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$50',
        status: 4
      },
      {
        responsive_id: '',
        id: 'HJ6823',
        avatar: 'avatar-s-10.jpg',
        full_name: 'Dr.Smith',
        doctorType: 'Doctor',
        treatment: 'General Checkup',
        date: '11/02/2021',
        balance: '$340',
        status: 4,
      },
    ]
}