<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://plutus.com/admin" target="_blank">Plutus</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
<!--   <span class="float-md-right d-none d-md-block">
    .. <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span> -->
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<!-- <div class="buy-now"> Test
  <a href="https://plutus.com/admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    > </a
  >
</div> -->
