export class CalendarFakeData {
  public static events = [
    {
      id: 1,
      visitType: 'Initial Visit',
      title:'Jane Foster',
      phone: '9474859694',
      date: new Date(),
      time: new Date(new Date().getTime() + 24*60*60*1000),
      insurance: 'Initial Visit',
      treatment: 'Physician',
      treatmentPrice: 25
    },
    {
      id: 2,
      visitType: 'Initial Visit',
      title:'Donna Frank',
      phone: '9474859695',
      date: new Date(),
      time: new Date(new Date().getTime() + 24*60*60*1000),
      insurance: 'Initial Visit',
      treatment: 'Physician',
      treatmentPrice: 25
    },
    {
      id: 3,
      visitType: 'Initial Visit',
      title:'Gabrielle Robertson',
      phone: '9474859696',
      date: new Date(),
      time: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      insurance: 'Initial Visit',
      treatment: 'Physician',
      treatmentPrice: 25
    },
    {
      id: 4,
      visitType: 'Initial Visit',
      title:'Lori Spears',
      phone: '9474859697',
      date: new Date(),
      time: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      insurance: 'Initial Visit',
      treatment: 'Physician',
      treatmentPrice: 25
    },
    {
      id: 5,
      visitType: 'Initial Visit',
      title:'Sandy Vega',
      phone: '9474859698',
      date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -11),
      time: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      insurance: 'Initial Visit',
      treatment: 'Physician',
      treatmentPrice: 25
    },
    // {
    //   id: 2,
    //   url: '',
    //   title: 'General Checkup',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -11),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -10),
    //   allDay: true,
    //   calendar: 'Dentist',
    //   extendedProps: {}
    // },
    // {
    //   id: 3,
    //   url: '',
    //   title: 'General Checkup',
    //   allDay: true,
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -9),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -7),
    //   calendar: 'Veterinary',
    //   extendedProps: {}
    // },
    // {
    //   id: 4,
    //   url: 'https://www.plutus.com',
    //   title: 'Up Coming Appointment',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -11),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -10),
    //   allDay: true,
    //   calendar: 'Veterinary',
    //   extendedProps: {}
    // },
    // {
    //   id: 5,
    //   url: '',
    //   title: 'Up Coming Appointment',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
    //   allDay: true,
    //   calendar: 'Veterinary',
    //   extendedProps: {
    //     location: 'Chicago',
    //     description: 'on a Trip',
    //     addGuest: []
    //   }
    // },
    // {
    //   id: 6,
    //   url: '',
    //   title: 'Weekly Appointment',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
    //   allDay: true,
    //   calendar: 'Veterinary',
    //   extendedProps: {}
    // },
    // {
    //   id: 7,
    //   url: '',
    //   title: 'Up Coming Appointment',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
    //   allDay: true,
    //   calendar: 'Physician',
    //   extendedProps: {
    //     location: 'Moscow',
    //     description: 'The party club'
    //   }
    // },
    // {
    //   id: 8,
    //   url: '',
    //   title: 'Up Coming Appointment',
    //   start: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -13),
    //   end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, -12),
    //   allDay: true,
    //   calendar: 'Physician',
    //   extendedProps: {
    //     location: 'Japan',
    //     description: 'weekend Drive'
    //   }
    // },
    // {
    //   id: 9,
    //   url: '',
    //   title: 'Up Coming Appointment',
    //   start:
    //     new Date().getMonth() === 11
    //       ? new Date(new Date().getFullYear() + 1, 0, 1)
    //       : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    //   end:
    //     new Date().getMonth() === 11
    //       ? new Date(new Date().getFullYear() + 1, 0, 1)
    //       : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    //   allDay: true,
    //   calendar: 'Physician',
    //   extendedProps: {}
    // },
    // {
    //   id: 10,
    //   url: '',
    //   title: 'Monthly Checkup',
    //   start:
    //     new Date().getMonth() === 11
    //       ? new Date(new Date().getFullYear() - 1, 0, 1)
    //       : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    //   end:
    //     new Date().getMonth() === 11
    //       ? new Date(new Date().getFullYear() - 1, 0, 1)
    //       : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    //   allDay: true,
    //   calendar: 'Veterinary',
    //   extendedProps: {}
    // }
  ];
  public static calendar = [
    { id: 1, filter: 'Physician', color: 'success', checked: true },
    { id: 2, filter: 'Dentist', color: 'warning', checked: true },
    { id: 3, filter: 'Veterinary', color: 'danger', checked: true },
    // { id: 4, filter: 'Gynic', color: 'warning', checked: true },
    // { id: 5, filter: 'Ortho', color: 'info', checked: true }
  ];
  public static patients = [
    {
      id: 1,
      firstName: 'Foster',
      lastName:'Jane',
      middleName: '9474859694',
      email: 'fosterjane@gmail.com',
      phoneNumber: '9474859694',
      age: 25,
      gender: 'Male',
      address:'India',
      insurance: 'Kotak',
      
    }
  ];
}
